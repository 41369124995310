import React from 'react';
import './styles.css';
import HomePage from './HomePage';
import Category from './Category';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import ProductView from './ProductView';
import SellerProfile from './SellerProfile';
import SearchPage from './SearchPage';


const App = () => {
    return (
 
        <Router>
            <Routes>

                <Route path='/' element={<HomePage />} />

                <Route exact path="/seller" element={<SellerProfile />} />
                <Route path="/product/:id" element={<ProductView />} />
                <Route path="/search/item" element={<SearchPage />} />

                {/* Categories Routing */}
                <Route exact path="/category/:name" element={<Category />} />
                <Route exact path="/category" element={<HomePage />} />

                <Route path="*" element={<Navigate to="/" />} />

                <Route path='/category/mobile-phones' element={<Category category="Mobile Phones" />} />
                <Route path='/category/cars' element={<Category category="Vehicles" />} />
                <Route path='/category/renting-in-ghana' element={<Category category="Accommodation" />} />
                <Route path='/category/appliances' element={<Category category="Appliances" />} />
                <Route path='/category/laptops-computers' element={<Category category="Laptops & Computers" />} />
                <Route path='/category/clothing' element={<Category category="Clothing" />} />
                <Route path='/category/accommodation' element={<Category category="Accommodation" />} />
                <Route path='/category/electronics' element={<Category category="Electronics" />} />
                <Route path='/category/furniture' element={<Category category="Furniture" />} />
                <Route path='/category/cosmetics' element={<Category category="Cosmetics" />} />
                <Route path='/category/groceries-food' element={<Category category="Groceries or Food" />} />
                <Route path='/category/books-stationery' element={<Category category="Books & Stationery" />} />
                <Route path='/category/services' element={<Category category="Services" />} />
                <Route path='/category/animals-pets' element={<Category category="Animals/Pets" />} />




            </Routes>
        </Router>


    );
};

export default App;
