import axios from 'axios';
import moment from 'moment';
export const COLOR_SECONDARY = "#3bc43b";
export const COLOR_PRIMARY = "#717";
export const COLOR_LIGHT = "#ad70ad";
export const COLOR_LIGHTER = "#e8cce8";
export const COLOR_ACCENT = "gold";
export const BG_COLOR = "#fff";
export const TEXT_FONT_SIZE = 14;
//  export const MAIN_URL = "https://ee9b-102-176-65-167.ngrok-free.app";
export const FILE_URL = "https://qloza.s3.eu-north-1.amazonaws.com";

export const MAIN_URL = "https://qloza-app-9be2501324fb.herokuapp.com";
export const CATEGORIES = [
    {
        image: ("phones.png"),
        id: 1,
        name: "Mobile Phones",
        tags: 'phones,telephones',
        extra: 'cheap mobile phones to buy,buy mobile phones ghana, affordable smartphones accra, latest phone deals tema, best phone prices kumasi, where to buy cellphones ghana.'

    },
    {
        "id": 2,
        "name": "Laptops & Computers",
        tags: 'computer,pc,laptop',
        image: ("laptops.png"),
        extra: 'buy laptops ghana, affordable computers accra, latest laptop deals tema, best computer prices kumasi, where to buy PCs ghana'

    },
    {
        image: ("vehicle.png"),
        id: 3,
        tags: 'cars,trucks,bicycle',
        name: "Vehicles",
        extra: "cheap cars for sale ghana,car prices in ghana,cars for sale ghana, car for sale accra,buy cars ghana, affordable vehicles accra, latest car deals tema, best car prices kumasi, where to buy automobiles ghana"

    },
    {
        "id": 4,
        "name": "Clothing",
        tags: 'attire,dress,clothes,shirts,trousers,bags',
        image: ("clothing.png"),
        extra: "buy dresses online ghana,men's shoes accra,ankara styles for sale tema,kids clothes kumasi,hair extensions ghana."

    },
    {
        id: 5,
        name: "Accommodation",
        tags: "rooms,houses,apartment,renting,single room,chamber and hall,self contain",
        image: ("house.png"),
        extra: 'room for rent accra,rooms,houses,apartment,renting,single room,chamber and hall,self contain',
        subcategories: [
            {
                id: 1,
                name: "Rooms for rent"
            },

        ]
    },
    {
        id: 6,
        name: "Electronics",
        tags: 'mobile phones,laptops',
        image: ("speakers.webp"),
        extra: "buy iphone accra,samsung galaxy prices in ghana,laptops for sale tema,cheap tvs online kumasi,affordable blenders accra"

    },
    {
        "id": 7,
        "name": "Furniture",
        tags: "Chair,table,desk,sofa",
        image: ("furniture.png"),
        extra: 'office chairs for sale in ghana, cheap chairs for sale, sofa for sale accra, tables for sale in ghana,price of office chairs in ghana'


    },
    {
        id: 8,
        name: "Cosmetics",
        tags: 'skincare,face',
        image: ("beauty.png"),
        extra: 'buy makeup online ghana, skincare products accra, hair products for natural hair tema, perfumes for men kumasi, ghanaian black soap.'

    },
    {
        "id": 9,
        "name": "Appliances",
        tags: 'kitchen,fridge,air con,air conditioner,refrigerator',
        image: ("electronics.png"),
        extra: 'cheap refrigerators for sale ghana, washing machines accra, kitchen appliances tema, electric fans kumasi, water dispensers ghana'


    },
    {
        "id": 10,
        "name": "Groceries or Food",
        tags: 'vegetables,goat,sheep,birds,fowl,chicken',
        image: ("grocery.png"),
        extra: 'online grocery shopping ghana, buy food online accra, fresh fruits and vegetables tema, meat delivery kumasi, ghanaian snacks'


    },
    {
        "id": 13,
        "name": "Books & Stationery",
        tags: 'pencils,pens,erasors,mathsets',
        image: ("stationery.png"),
        extra: 'stationery shops in ghana,exercise books for sale in ghana accra, kumasi books and stationery'


    },
    {
        "id": 11,
        "name": "Services",
        tags: 'services,company,firms',
        image: ("services.png"),


    },
    {
        "id": 12,
        "name": "Animals/Pets",
        tags: 'cats,dogs,birds',
        image: ("pets.png"),
        extra: 'cheap dogs for sale in ghana,dogs for sale in accra,pets for sale ghana,price of dog in accra, price of kittens in ghana,price of puppy in ghana'


    },


]

export const truncateString = (text: string): string => {

    if (text.length < 15)
        return text
    return `${text.substring(0, 15)}...`
}

export function generateRandomName(length) {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let randomName = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        randomName += charset.charAt(randomIndex);
    }

    return randomName;
}


export const formatDate = (dateString) => {
    const date = moment(dateString);

    const formattedDate = date.format('MMMM Do, YYYY');

    const relativeTime = date.fromNow();

    return `${relativeTime}`;
};


export const formatTimestamp = (timestamp: string) => {
    const messageTime = moment(timestamp);
    const now = moment();

    const sameDay = now.isSame(messageTime, 'day');

    if (sameDay) {
        return 'Today at ' + messageTime.format('h:mm a');
    } else if (now.clone().subtract(1, 'day').isSame(messageTime, 'day')) {
        return 'Yesterday at ' + messageTime.format('h:mm a');
    } else if (now.diff(messageTime, 'weeks') < 1) {
        return messageTime.format('dddd [at] h:mm a');
    } else {
        return messageTime.format('MMMM D, YYYY [at] h:mm a');
    }

};

export const updateViewCount = async (productId: string) => {
    try {
        const response = await axios.put(`${MAIN_URL}/product/${productId}/update-item-views`);
        console.log(response.data);
    } catch (error) {
        console.error('Error updating view count:');
    }
};


