import React, { useState, useEffect } from 'react';
import { MdLocationOn } from 'react-icons/md';
import { FaHome, FaCart } from 'react-icons/fa';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { MdMenu } from 'react-icons/md';
import { FaWhatsapp, FaPhone, FaInstagram } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';

import { MAIN_URL, FILE_URL, COLOR_PRIMARY, COLOR_LIGHT, COLOR_LIGHTER, CATEGORIES } from './Constants.ts';

const SellerProfile = () => {
    const [products, setProducts] = useState([]);
    const { name } = useParams();
    const [showMenu, setShowMenu] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { uid, business, phone_number, token_, profile_pic } = location.state.seller;
    const [sellerInfo, setSellerInfo] = useState(null);
    const [itemsList, setItemsList] = useState([]);
    const [showNull, setShowNull] = useState(false)
    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const [searchTerm, setSearchTerm] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const playStoreURL = 'https://play.google.com/store/apps/details?id=com.cenim.qloza';
    const appStoreURL = 'https://apps.apple.com/cz/app/qloza-ghana-buy-sell-online/id6474622031';


    const handleInputChange = (event) => {


        const value = event.target.value;
        setSearchTerm(value);
        if (value.trim() === '') {
            setShowSuggestions(false);
        } else {

            handleSearch(value, "");


        }



    };

    const handleKeyPress = e => {
        if (e.key === "Enter" && searchTerm.trim() !== '') {

            e.preventDefault();

            navigate(`/search/item`, { state: { param: searchTerm } });
        }


    }


    const handleSuggestionClick = (value) => {
        setSearchTerm(value);
        setShowSuggestions(false);

    };

    const handleSearch = async (term, useterm) => {


        await axios.get(`${MAIN_URL}/q-search/search-products`, {
            params: {
                searchTerm: term.toLowerCase(),

            },
        })
            .then(response => {
                const results = response.data
                if (results) {
                    setShowSuggestions(true);
                    setSuggestions(results);

                }

                if (useterm === "search") {
                    setProducts(response.data)

                }



            })
            .catch(error => {

            });
    };
    useEffect(() => {
        const fetchSellerData = async () => {
            try {
                const profileResponse = await axios.get(`${MAIN_URL}/seller/${uid}`);
                setSellerInfo(profileResponse.data);


                setShowNull(false)
                const itemsResponse = await axios.get(`${MAIN_URL}/${uid}/store/items`);
                setItemsList(itemsResponse.data.sellerItems);
                setShowNull(true)

            } catch (error) {
                console.error('Error fetching data:', error.request);
            }
        };

        fetchSellerData();
    }, [uid]);


    const phoneNumber = phone_number;
    const formatNum = phoneNumber && phoneNumber.startsWith('0') ? "+233" + parseInt(phoneNumber) : phoneNumber

    const openWhatsApp = () => {

        window.open(`https://wa.me/${formatNum}`, '_blank');
    };

    const initiatePhoneCall = () => {
        window.open(`tel:${formatNum}`);
    };


    return (
        <div style={{ minHeight: '100vh', position: 'relative' }}>
            <head>
                <meta name="google-adsense-account" content="ca-pub-5621810786232974"></meta>
            </head>

            {/* Fixed Header */}
            <div className="fixed-header" style={{
                marginTop: '20px',
                marginBottom: '20px',
                color: COLOR_LIGHTER,
                position: 'fixed',
                top: -20,
                width: '100%',
                backgroundColor: COLOR_PRIMARY,
                zIndex: '1000',
                display: 'flex',
                alignItems: 'center',
                padding: '10px'
            }}>
                {/* Menu Icon */}
                <div style={{ textAlign: 'right' }}>
                    <MdMenu size={30} style={{ cursor: 'pointer' }} onClick={toggleMenu} />
                </div>

                {/* Expanded Menu */}
                {showMenu && (
                    <div style={{
                        backgroundColor: COLOR_LIGHTER, padding: '10px', border: '1px solid #ccc',
                        position: 'fixed', zIndex: '1', marginTop: '40%', marginLeft: '2%'
                    }}>
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                            {CATEGORIES.map((category) => (
                                <Link
                                    to={`/category/${category.name}`}
                                    state={{ category: category.name }}
                                    style={{ textDecoration: 'none', color: 'inherit' }}
                                >
                                    <li key={category.id} style={{
                                        marginBottom: '5px', borderBottomColor: '#f00',
                                        borderBottomWidth: '1px'
                                    }} onClick={toggleMenu} >
                                        <p style={{ color: COLOR_PRIMARY }}>{category.name}</p>
                                    </li>
                                </Link>
                            ))}
                        </ul>
                    </div>
                )}
                <Link to="/" style={{ textDecoration: 'none', color: COLOR_PRIMARY }}>
                    <img
                        src={`/images/qloza_.png`}
                        className="card-img-top"
                        loading='eager'
                        alt={"Home"}
                        style={{
                            objectFit: 'cover',
                            width: '150px',
                            height: '110px',
                            marginTop: -20,
                            marginRight: '10px'
                        }}
                    />
                </Link>
                <div className="col-md-6">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="form-control mb-3"
                        style={{ width: '100%' }}
                        value={searchTerm}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyPress}
                    />
                    {showSuggestions && (
                        <ul className="list-group" style={{
                            zIndex: 12, position: 'absolute',
                            width: '40%', display: searchTerm ? 'flex' : 'none'
                        }}>
                            <Link
                                to={`/search/item/`}
                                state={{ param: searchTerm }}
                                style={{ textDecoration: 'none', color: 'inherit' }}>
                                <li

                                    className="list-group-item"
                                    onClick={() => handleSuggestionClick(searchTerm)}
                                    style={{ cursor: 'pointer', display: searchTerm ? 'flex' : 'none' }}>
                                    {searchTerm}
                                </li>
                            </Link>
                            {suggestions.map((suggestion, index) => (
                                <Link
                                    to={`/search/item/`}
                                    state={{ param: suggestion.name }}
                                    style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <li
                                        key={index}
                                        className="list-group-item"
                                        onClick={() => handleSuggestionClick(suggestion.name)}
                                        style={{ cursor: 'pointer' }}>
                                        {suggestion.name}
                                    </li>
                                </Link>
                            ))}
                        </ul>
                    )}
                </div>
                <div className="col-md-6"> {/* Optional: Adjust width according to your layout */}
                    <p style={{ padding: 10 }}>{name}</p>
                </div>
            </div>



            <div className="row" style={{ padding: '10px', marginTop: '150px', marginLeft: 15, marginRight: 15 }}>
                {/* <p>{JSON.stringify(location.state)}</p> */}
                <div className='row' style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 110,
                    justifyContent: 'center',
                    width: '100%',
                    padding: 20,
                    margin: 0,
                    flexDirection: 'column',
                    // backgroundColor: COLOR_LIGHT,
                }}>
                    {profile_pic && <img loading='eager' src={`${FILE_URL}/${profile_pic}`} style={{
                        width: 160,
                        height: 150,
                        borderRadius: 75,
                        alignSelf: 'center',
                    }}
                        alt={'profile'}
                    />
                    }
                    <h3 style={{ color: COLOR_PRIMARY }}>{business}</h3>
                    <h6>Phone Number : <span style={{ color: COLOR_LIGHT }}>{phone_number}</span></h6>
                    <div>
                        <button onClick={openWhatsApp} className="btn btn-success" style={{ marginRight: 10, flex: 1 }}>
                            <FaWhatsapp /> Chat
                        </button>
                        <button onClick={initiatePhoneCall} className="btn btn-info" style={{ flex: 1 }}>
                            <FaPhone style={{ rotate: '90deg' }} />  Call
                        </button>
                    </div>
                </div>

                <p style={{ marginTop: 10 }}>All products by  {business}</p>
                {itemsList.map((item) => (
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6" key={item.id}>

                        <Link to={`/product/${item._id}`} state={{ product: item }}
                            style={{ textDecoration: 'none', color: COLOR_PRIMARY }}>
                            <div className="card" style={{ backgroundColor: '#fcfcfc', borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
                                <img
                                    src={`${FILE_URL}/${item.images[0]}`}
                                    className="card-img-top"
                                    loading='eager'
                                    alt={item.name}
                                    style={{ objectFit: 'cover', width: '100%', height: '200px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}
                                />
                                <div className="card-body">
                                    <h5 className="card-text" style={{ color: COLOR_PRIMARY, fontWeight: 'bold', fontSize: '18px' }}>
                                        GH₵ {parseFloat(item.price).toLocaleString()}
                                    </h5>
                                    <h5 className="card-title" style={{ textTransform: 'capitalize', color: '#000', fontSize: '16px' }}>
                                        {item.name}
                                    </h5>
                                    <p className="card-text" style={{
                                        color: COLOR_LIGHT, textTransform: 'capitalize',
                                        marginBottom: '5px', fontSize: 14
                                    }}>
                                        <MdLocationOn size={20} color={COLOR_LIGHTER} />
                                        {item.location}
                                    </p>
                                </div>
                            </div>
                        </Link>

                    </div>
                ))}
            </div>



            <footer className="row mx-auto py-5" style={{ backgroundColor: COLOR_PRIMARY, color: '#fff', paddingLeft: '10%' }}>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                    &copy; {new Date().getFullYear()} Qloza E-commerce. All Rights Reserved.
                    <div style={{ marginTop: '10px' }}>
                        <span style={{ color: COLOR_LIGHTER }}>Download Qloza to your phone below</span>
                        <div className="d-flex align-items-center mt-2">
                            <a href={playStoreURL} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>
                                <img
                                    src={`/images/playstore.png`}
                                    style={{ width: '140px', height: '50px' }}
                                    alt={"Download on Play Store"}
                                />
                            </a>
                            <a href={appStoreURL} target="_blank" rel="noopener noreferrer">
                                <img
                                    src={`/images/appstore.png`}
                                    style={{ width: '120px', height: '40px' }}
                                    alt={"Download on App Store"}
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                    <a href="https://www.instagram.com/qlozagh/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: COLOR_LIGHTER, display: 'flex', alignItems: 'center' }}>
                        <FaInstagram size={24} style={{ color: '#C13584', marginRight: '5px' }} /> Qloza Ghana
                    </a>
                </div>
            </footer>
        </div>
    );
};

export default SellerProfile;
