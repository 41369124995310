import React, { useState, useEffect } from 'react';
import { MdLocationOn } from 'react-icons/md';
import { MAIN_URL, FILE_URL, COLOR_PRIMARY, COLOR_LIGHT, COLOR_LIGHTER, CATEGORIES, formatDate } from './Constants.ts';
import { MdMenu } from 'react-icons/md';
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from 'react-alice-carousel';
import './App.css'
import { FaWhatsapp, FaPhone, FaInstagram } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet';


const ProductView = () => {


    const [Images, setImages] = useState([]);
    const [imageSlide, setImageSlide] = useState([]);
    const [showMenu, setShowMenu] = useState(false);
    const [similarItems, setSimilarItems] = useState([]);
    const [sellerData, setSellerData] = useState({})

    const location_ = useLocation();

    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [description, setDescription] = useState('');
    const [images, set_Images] = useState([]);
    const [tags, setTags] = useState([]);
    const [category, setCategory] = useState('');
    const [_id, setId] = useState('');
    const [uid, setUid] = useState('');
    const [date_added, setDateAdded] = useState('');
    const [location, setLocation] = useState('');
    const phoneNumber = sellerData.phone_number;


    const formatNum = phoneNumber && phoneNumber.startsWith('0') ? "+233" + parseInt(phoneNumber) : phoneNumber

    const openWhatsApp = () => {

        window.open(`https://wa.me/${formatNum}`, '_blank');
    };

    const initiatePhoneCall = () => {
        window.open(`tel:${formatNum}`);
    };

    const [product, setProduct] = useState(null)
    const { id } = useParams();
    useEffect(() => {
        // setImages(images);


        const fetchProductData = async () => {
            try {
                const response = await axios.get(`${MAIN_URL}/product/${id}`);
                const productData = response.data;

                setProduct(productData);
                setName(productData.name);
                setPrice(productData.price);
                setDescription(productData.description);
                setImages(productData.images);
                setTags(productData.tags);
                setCategory(productData.category);
                setId(productData._id);
                setUid(productData.uid);
                setDateAdded(productData.date_added);
                setLocation(productData.location);

                setImages(productData.images);

                const pics = productData.images.map((image) => ({
                    thumbnail: `${FILE_URL}/${image}`,
                    original: `${FILE_URL}/${image}`,
                }));
                setImageSlide(pics);

                console.log(JSON.stringify(productData))
            } catch (error) {
                console.error('Error fetching product details:', error);
            }


            if (Images && Images.length > 0) {


                const pics = [];
                // eslint-disable-next-line array-callback-return
                Images.map((image) => {
                    pics.push({
                        thumbnail: `${FILE_URL}/${image}`,
                        original: `${FILE_URL}/${image}`
                    })
                })
                setImageSlide(pics);
            }

        };
        fetchProductData()
    }, [id]);

    const playStoreURL = 'https://play.google.com/store/apps/details?id=com.cenim.qloza';
    const appStoreURL = 'https://apps.apple.com/cz/app/qloza-ghana-buy-sell-online/id6474622031';

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const [products, setProducts] = useState([]);
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    const handleInputChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        if (value.trim() === '') {
            setShowSuggestions(false);
        } else {
            handleSearch(value, "")
        }
    };
    const handleKeyPress = e => {
        if (e.key === "Enter" && searchTerm.trim() !== '') {
            e.preventDefault();
            navigate(`/search/item`, { state: { param: searchTerm } });
        }
    }
    const handleSuggestionClick = (value) => {
        setSearchTerm(value);
        setShowSuggestions(false);

    };
    const handleSearch = async (term, useterm) => {
        await axios.get(`${MAIN_URL}/q-search/search-products`, {
            params: {
                searchTerm: term.toLowerCase(),

            },
        })
            .then(response => {
                const results = response.data
                if (results) {
                    setShowSuggestions(true);
                    setSuggestions(results);

                }

                if (useterm === "search") {
                    setProducts(response.data)

                }
            })
            .catch(error => {
            });
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optional: Smooth scrolling animation
        });
    };

    useEffect(() => {
        scrollToTop()
        const loadSimilarProducts = async () => {
            try {
                const response = await axios.get(`${MAIN_URL}/load/similar-products`, {
                    params: {
                        tags: tags,
                        name: name,
                        category: category,
                        excluded: _id
                    }
                });
                setSimilarItems(response.data.similarProducts || []);
            } catch (error) {
                console.error('Error fetching similar products:', error);
            }
        };
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${MAIN_URL}/seller/${uid}`);
                setSellerData(response.data);
            } catch (error) {
                console.error('Error fetching user data:', error.request);
            }
        };
        loadSimilarProducts();
        fetchUserData()
    }, [name, tags, category, _id, uid]);

    return (
        <div style={{ minHeight: '100vh', position: 'relative' }}>
            <Helmet>
                <link rel="canonical" href="https://qloza.com" />
                <link rel="icon" href="qloza_icon.png" />

                <title>{name} - for sale in Ghana Qloza Ghana</title>
                <meta name="description" content={description} />
                <meta property="og:title" content={name} />
                <meta property="og:description" content={description} />
                <meta property='og:desription' content={`${name} available in ${location}`} />
                <meta property="og:image" content={`${FILE_URL}/${images[0]}`} />
                <meta property="og:type" content="product" />
                <meta property="og:price:amount" content={price} />
                <meta property="og:price:currency" content="GH₵" />
                <meta name="google-adsense-account" content="ca-pub-5621810786232974"></meta>

            </Helmet>

            <div className="fixed-header" style={{
                marginTop: '20px',
                marginBottom: '20px',
                color: COLOR_LIGHTER,
                position: 'fixed',
                top: -20,
                width: '100%',
                backgroundColor: COLOR_PRIMARY,
                zIndex: '1000',
                display: 'flex',
                alignItems: 'center',
                padding: '10px'
            }}>
                {/* Menu Icon */}
                <div style={{ textAlign: 'right' }}>
                    <MdMenu size={30} style={{ cursor: 'pointer' }} onClick={toggleMenu} />
                </div>

                {/* Expanded Menu */}
                {showMenu && (
                    <div style={{
                        backgroundColor: COLOR_LIGHTER, padding: '10px', border: '1px solid #ccc',
                        position: 'fixed', zIndex: '1', marginTop: '40%', marginLeft: '2%'
                    }}>
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                            {CATEGORIES.map((category) => (
                                <Link
                                    to={`/category/${category.name}`}
                                    state={{ category: category.name }}
                                    style={{ textDecoration: 'none', color: 'inherit' }}
                                >
                                    <li key={category.id} style={{
                                        marginBottom: '5px', borderBottomColor: '#f00',
                                        borderBottomWidth: '1px'
                                    }} onClick={toggleMenu} >
                                        <p style={{ color: COLOR_PRIMARY }}>{category.name}</p>
                                    </li>
                                </Link>
                            ))}
                        </ul>
                    </div>
                )}
                <Link to="/" style={{ textDecoration: 'none', color: COLOR_PRIMARY }}>
                    <img
                        src={`/images/qloza_.png`}
                        className="card-img-top"
                        loading='lazy'
                        alt={"Home"}
                        style={{
                            objectFit: 'cover',
                            width: '150px',
                            height: '110px',
                            marginTop: -20,
                            marginRight: '10px'
                        }}
                    />
                </Link>

                <div style={{ flex: 1 }}>
                    <input
                        type="text"
                        placeholder="Search..."
                        className="form-control mb-3"
                        style={{ width: '93%' }}
                        value={searchTerm}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyPress}
                    />
                    {showSuggestions && (
                        <ul className="list-group" style={{
                            zIndex: 12, position: 'absolute',
                            width: '40%', display: searchTerm ? 'flex' : 'none'
                        }}>
                            <Link
                                to={`/search/item/`}
                                state={{ param: searchTerm }}
                                style={{ textDecoration: 'none', color: 'inherit' }}>
                                <li

                                    className="list-group-item"
                                    onClick={() => handleSuggestionClick(searchTerm)}
                                    style={{ cursor: 'pointer', display: searchTerm ? 'flex' : 'none' }}>
                                    {searchTerm}
                                </li>
                            </Link>
                            {suggestions.map((suggestion, index) => (
                                <Link
                                    to={`/search/item/`}
                                    state={{ param: suggestion.name }}
                                    style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <li
                                        key={index}
                                        className="list-group-item"
                                        onClick={() => handleSuggestionClick(suggestion.name)}
                                        style={{ cursor: 'pointer' }}>
                                        {suggestion.name}
                                    </li>
                                </Link>
                            ))}
                        </ul>
                    )}
                </div>


            </div>


            <div className="container" style={{ marginTop: '120px', marginBottom: '20px', color: COLOR_PRIMARY }}>
                <div style={{ marginBottom: '10%', justifySelf: 'center', justifyContent: 'center' }}>



                    <ImageGallery items={imageSlide} />

                    <div style={{ marginLeft: '20%', }}>
                        <p>Phone Number : <span>{sellerData.phone_number}</span></p>

                        <div>
                            <button onClick={openWhatsApp} className="btn btn-success" style={{ marginRight: 10, width: '20%' }}>
                                <FaWhatsapp /> Chat
                            </button>
                            <button onClick={initiatePhoneCall} className="btn btn-info" style={{ width: '20%' }}>
                                <FaPhone /> Call
                            </button>
                        </div>
                        <p style={{ color: "#aaa", padding: 10, marginTop: -5 }}>Posted
                            {" " + formatDate(date_added)} by

                            <Link to={`/seller`} state={{ seller: { ...sellerData, uid } }}
                                style={{ textDecoration: 'none', color: COLOR_PRIMARY }}>
                                <b style={{ color: COLOR_PRIMARY }}>{" " + sellerData.business}</b>
                            </Link>
                        </p>

                        <p className="card-text" style={{
                            color: COLOR_LIGHT, marginTop: -20,
                            textTransform: 'capitalize'
                        }}>
                            <MdLocationOn size={20} color={COLOR_LIGHTER} />
                            {location}
                        </p>
                        <h2 style={{ color: "#000" }}>{name}</h2>
                        <h3 style={{ fontWeight: 'bold' }}> GH₵ {price}</h3>
                        <h6 style={{ color: '#333' }}> {description}</h6>
                    </div>
                </div>

                <h4 style={{ padding: 10, color: '#555', marginTop: 10 }}>Similar Products</h4>

                <div className="row">
                    {similarItems.map((item) => (
                        <div className="col-lg-3 col-md-4 col-6 mb-3" key={item.id}>
                            <a href={`/product/${item._id}`} state={{ product: item }}
                                key={item._id}
                                style={{ textDecoration: 'none', color: COLOR_PRIMARY }}>
                                <div className="card" style={{ backgroundColor: '#fcfcfc', borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
                                    <img
                                        src={`${FILE_URL}/${item.images[0]}`}
                                        className="card-img-top"
                                        loading='lazy'
                                        alt={item.name}
                                        style={{ objectFit: 'cover', width: '100%', height: '200px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}
                                    />
                                    <div className="card-body">
                                        <h5 className="card-text" style={{ color: COLOR_PRIMARY, fontWeight: 'bold', fontSize: '18px' }}>
                                            GH₵ {parseFloat(item.price).toLocaleString()}
                                        </h5>
                                        <h5 className="card-title" style={{ textTransform: 'capitalize', color: '#000', fontSize: '16px' }}>
                                            {item.name}
                                        </h5>
                                        <p className="card-text" style={{
                                            color: COLOR_LIGHT, textTransform: 'capitalize',
                                            marginBottom: '5px', fontSize: 14
                                        }}>
                                            <MdLocationOn size={20} color={COLOR_LIGHTER} />
                                            {item.location}
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>


            </div>

            {
                <footer className="row mx-auto py-5" style={{ backgroundColor: COLOR_PRIMARY, color: '#fff', paddingLeft: '10%' }}>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        &copy; {new Date().getFullYear()} Qloza E-commerce. All Rights Reserved.
                        <div style={{ marginTop: '10px' }}>
                            <span style={{ color: COLOR_LIGHTER }}>Download Qloza to your phone below</span>
                            <div className="d-flex align-items-center mt-2">
                                <a href={playStoreURL} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>
                                    <img
                                        src={`/images/playstore.png`}
                                        style={{ width: '140px', height: '50px' }}
                                        alt={"Download on Play Store"}
                                    />
                                </a>
                                <a href={appStoreURL} target="_blank" rel="noopener noreferrer">
                                    <img
                                        src={`/images/appstore.png`}
                                        style={{ width: '120px', height: '40px' }}
                                        alt={"Download on App Store"}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <a href="https://www.instagram.com/qlozagh/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: COLOR_LIGHTER, display: 'flex', alignItems: 'center' }}>
                            <FaInstagram size={24} style={{ color: '#C13584', marginRight: '5px' }} /> Qloza Ghana
                        </a>
                    </div>
                </footer>

            }

        </div>
    );
};

export default ProductView;
