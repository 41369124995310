import React, { useState, useEffect } from 'react';
import { MdLocationOn } from 'react-icons/md';
import { MAIN_URL, FILE_URL, COLOR_PRIMARY, COLOR_LIGHT, COLOR_LIGHTER, CATEGORIES } from './Constants.ts';
import { Link } from 'react-router-dom';
import { MdMenu } from 'react-icons/md';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaHome, FaCart } from 'react-icons/fa';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { FaWhatsapp, FaPhone } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet'
import { FaInstagram } from 'react-icons/fa';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


const HomePage = () => {
    const [items, setItems] = useState([]);
    const IMAGE_BASE_URL = '/images/categories';
    const playStoreURL = 'https://play.google.com/store/apps/details?id=com.cenim.qloza';
    const appStoreURL = 'https://apps.apple.com/cz/app/qloza-ghana-buy-sell-online/id6474622031';

    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };



    const [products, setProducts] = useState([]);
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    const handleInputChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        if (value.trim() === '') {
            setShowSuggestions(false);
        } else {
            handleSearch(value, "")
        }
    };
    const handleKeyPress = e => {
        if (e.key === "Enter" && searchTerm.trim() !== '') {
            e.preventDefault();
            navigate(`/search/item`, { state: { param: searchTerm } });
        }
    }
    const handleSuggestionClick = (value) => {
        setSearchTerm(value);
        setShowSuggestions(false);

    };
    const handleSearch = async (term, useterm) => {
        await axios.get(`${MAIN_URL}/q-search/search-products`, {
            params: {
                searchTerm: term.toLowerCase(),

            },
        })
            .then(response => {
                const results = response.data
                if (results) {
                    setShowSuggestions(true);
                    setSuggestions(results);

                }

                if (useterm === "search") {
                    setProducts(response.data)

                }
            })
            .catch(error => {
            });
    };
    useEffect(() => {
        document.title = 'Qloza Ghana - Buy, Sell, Rent';
        //   document.body.addEventListener('click', () => {
        //     setShowMenu(false)
        // });

        //  return () => {
        //     document.body.removeEventListener('click', () => {
        //         setShowMenu(false)
        //     });
        // };
    }, []);



    useEffect(() => {

        fetch(`${MAIN_URL}/load-home-items`)
            .then(response => response.json())
            .then(data => {
                setItems(data);
                console.log(data)
                console.log(data[0].images[0])
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    return (
        <div style={{ minHeight: '100vh', position: 'relative' }}>

            <Helmet>
                <link rel="canonical" href="https://qloza.com" />
                <title>Qloza Ghana - Buy, Sell, Rent</title>
                <meta name="description" content="Discover the best Ghana products: mobile phones, renting, clothing, accessories,
                 and more at Qloza Ghana online market place. Shop now for quality products at great prices."/>
                <link rel="icon" href="qloza_icon.png" />

                <meta name="google-adsense-account" content="ca-pub-5621810786232974" />

            </Helmet>



            <div className="fixed-header" style={{
                marginTop: '20px',
                marginBottom: '20px',
                color: COLOR_LIGHTER,
                position: 'fixed',
                top: -20,
                width: '100%',
                backgroundColor: COLOR_PRIMARY,
                zIndex: '1000',
                display: 'flex', // Use flexbox for layout
                alignItems: 'center', // Align items vertically in the center
                padding: '10px', // Add padding for spacing
            }}>


                <div style={{ textAlign: 'left' }} >
                    <MdMenu size={30} style={{ cursor: 'pointer' }} onClick={toggleMenu} />
                </div>

                <div>
                    {showMenu && (
                        <div style={{
                            backgroundColor: COLOR_LIGHTER, padding: '10px', border: '1px solid #ccc',
                            position: 'fixed', zIndex: 100, marginTop: '10%', marginLeft: '2%'
                        }}>
                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                                {CATEGORIES.map((category) => (
                                    <Link
                                        to={`/category/${category.name}`}
                                        state={{ category: category.name }}
                                        style={{ textDecoration: 'none', color: 'inherit' }} >
                                        <li key={category.id} style={{
                                            marginBottom: '5px', borderBottomColor: '#f00',
                                            borderBottomWidth: '1px'
                                        }} onClick={toggleMenu} >
                                            <p style={{ color: COLOR_PRIMARY }}>{category.name}</p>
                                        </li>
                                    </Link>
                                ))}

                            </ul>
                        </div>
                    )}
                    <Link to="/" style={{ textDecoration: 'none', color: COLOR_PRIMARY }}>
                        <img
                            src={`/images/qloza_.png`}
                            className="card-img-top"
                            loading='lazy'
                            alt={"Home"}

                            style={{
                                objectFit: 'cover',
                                width: '140px',
                                height: '110px',
                                marginTop: -20,
                                marginRight: '10px'
                            }}
                        />
                    </Link>

                </div>
                <div style={{ flex: 2 }}>
                    <input
                        type="text"
                        placeholder="Search..."
                        className="form-control mb-3"
                        style={{ width: '93%' }}
                        value={searchTerm}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyPress}
                    />
                    {showSuggestions && (
                        <ul className="list-group" style={{
                            zIndex: 12, position: 'absolute',
                            width: '40%', display: searchTerm ? 'flex' : 'none'
                        }}>
                            <Link
                                to={`/search/item/`}
                                state={{ param: searchTerm }}
                                style={{ textDecoration: 'none', color: 'inherit' }}>
                                <li

                                    className="list-group-item"
                                    onClick={() => handleSuggestionClick(searchTerm)}
                                    style={{ cursor: 'pointer', display: searchTerm ? 'flex' : 'none' }}>
                                    {searchTerm}
                                </li>
                            </Link>
                            {suggestions.map((suggestion, index) => (
                                <Link
                                    to={`/search/item/`}
                                    state={{ param: suggestion.name }}
                                    style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <li
                                        key={index}
                                        className="list-group-item"
                                        onClick={() => handleSuggestionClick(suggestion.name)}
                                        style={{ cursor: 'pointer' }}>
                                        {suggestion.name}
                                    </li>
                                </Link>
                            ))}
                        </ul>
                    )}
                </div>


            </div>

            <div className="container" style={{ marginTop: '20px', marginBottom: '20px', color: COLOR_PRIMARY }}>





                <input type="text" placeholder="Search..." className="form-control mb-3" />

                <div className="mb-4" style={{ marginTop: 50 }}>
                    <h3>Categories</h3>
                    <div className="row">
                        {CATEGORIES.map((category) => (
                            <div className="col-lg-4 col-md-6 col-6 mb-3" key={category.id}>
                                <Link
                                    to={`/category/${category.name}`}
                                    state={{ category: category.name }}
                                    style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <div className="card">
                                        <LazyLoadImage
                                            src={`${IMAGE_BASE_URL}/${category.image}`}
                                            className="card-img-top"
                                            alt={category.name}
                                            effect={'blur'}
                                            style={{ objectFit: 'contain', height: '200px' }}
                                        />
                                        <div className="card-body">
                                            <h5 className="card-title" style={{ color: COLOR_PRIMARY, fontSize: '20px', textAlign: 'center' }}>{category.name}</h5>
                                            {/* <p className="card-text" style={{ textAlign: 'center' }}>{category.tags}</p> */}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>

                </div>


                <h4 style={{ padding: 10, color: '#555' }}>Recently Added to Qloza</h4>
                <div className="row">
                    {items.map((item) => (
                        <div className="col-lg-3 col-md-4 col-6 mb-3" key={item._id}>
                            <Link to={`/product/${item._id}`} state={{ product: item }}
                                style={{ textDecoration: 'none', color: COLOR_PRIMARY }}>
                                {/* <p>{JSON.stringify(item)}</p> */}

                                <div className="card" style={{ backgroundColor: '#fcfcfc', borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
                                    <LazyLoadImage
                                        src={`${FILE_URL}/${item.images[0]}`}
                                        className="card-img-top"
                                        alt={item.name}
                                        effect={'blur'}
                                        style={{ objectFit: 'cover', width: '100%', height: '200px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}
                                    />
                                    <div className="card-body">
                                        <h5 className="card-text" style={{ color: COLOR_PRIMARY, fontWeight: 'bold', fontSize: '18px' }}>
                                            GH₵ {parseFloat(item.price).toLocaleString()}
                                        </h5>
                                        <h5 className="card-title" style={{ textTransform: 'capitalize', color: '#000', fontSize: '16px' }}>
                                            {item.name}
                                        </h5>
                                        <p className="card-text" style={{
                                            color: COLOR_LIGHT, textTransform: 'capitalize',
                                            marginBottom: '5px', fontSize: 14
                                        }}>
                                            <MdLocationOn size={20} color={COLOR_LIGHTER} />
                                            {item.location}
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>


            </div>

            {/* Footer */}
            {
                <footer className="row mx-auto py-5" style={{ backgroundColor: COLOR_PRIMARY, color: '#fff', paddingLeft: '10%' }}>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        &copy; {new Date().getFullYear()} Qloza E-commerce. All Rights Reserved.
                        <div style={{ marginTop: '10px' }}>
                            <span style={{ color: COLOR_LIGHTER }}>Download Qloza to your phone below</span>
                            <div className="d-flex align-items-center mt-2">
                                <a href={playStoreURL} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>
                                    <img
                                        src={`/images/playstore.png`}
                                        style={{ width: '140px', height: '50px' }}
                                        alt={"Download on Play Store"}

                                    />
                                </a>
                                <a href={appStoreURL} target="_blank" rel="noopener noreferrer">
                                    <img
                                        src={`/images/appstore.png`}
                                        style={{ width: '120px', height: '40px' }}
                                        alt={"Download on App Store"}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <a href="https://www.instagram.com/qlozagh/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: COLOR_LIGHTER, display: 'flex', alignItems: 'center' }}>
                            <FaInstagram size={24} style={{ color: '#C13584', marginRight: '5px' }} /> Qloza Ghana
                        </a>
                    </div>
                </footer>

            }

        </div>
    );
};

export default HomePage;
