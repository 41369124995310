import React, { useState, useEffect } from 'react';
import { MdLocationOn, MdMenu } from 'react-icons/md';
import { FaHome, FaCart } from 'react-icons/fa';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaWhatsapp, FaPhone, FaInstagram } from 'react-icons/fa';

import { Helmet } from 'react-helmet';

import 'bootstrap/dist/css/bootstrap.min.css';

import { MAIN_URL, FILE_URL, COLOR_PRIMARY, COLOR_LIGHT, COLOR_LIGHTER, CATEGORIES } from './Constants.ts';

const Category = ({ category }) => {
    const [products, setProducts] = useState([]);
    const [showMenu, setShowMenu] = useState(false);

    let { name } = useParams();
    const location = useLocation();
    name = name || (location.state && location.state.category) || category;




    const playStoreURL = 'https://play.google.com/store/apps/details?id=com.cenim.qloza';
    const appStoreURL = 'https://apps.apple.com/cz/app/qloza-ghana-buy-sell-online/id6474622031';


    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    const findCategoryByName = () => {
        return CATEGORIES.find(category => category.name.toLowerCase() === name.toLowerCase());
    }

    const { extra, image } = findCategoryByName();
    const handleInputChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        if (value.trim() === '') {
            setShowSuggestions(false);
        } else {
            handleSearch(value, "")
        }
    };
    const handleKeyPress = e => {
        if (e.key === "Enter" && searchTerm.trim() !== '') {
            e.preventDefault();
            navigate(`/search/item`, { state: { param: searchTerm } });
        }
    }
    const handleSuggestionClick = (value) => {
        setSearchTerm(value);
        setShowSuggestions(false);

    };

    const handleSearch = async (term, useterm) => {
        await axios.get(`${MAIN_URL}/q-search/search-products`, {
            params: {
                searchTerm: term.toLowerCase(),

            },
        })
            .then(response => {
                const results = response.data
                if (results) {
                    setShowSuggestions(true);
                    setSuggestions(results);

                }

                if (useterm === "search") {
                    setProducts(response.data)

                }
            })
            .catch(error => {
            });
    };


    useEffect(() => {
        const loadMyProducts = async () => {
            try {
                const response = await axios.get(`${MAIN_URL}/qloza-products/${name}`);
                setProducts(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        loadMyProducts();
    }, [name]);

    return (
        <body>
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', position: 'relative' }}>

                <Helmet>
                    <title>{`${name} for sale in Ghana on Qloza.com`}</title>
                    <meta name="google-adsense-account" content="ca-pub-5621810786232974" />
                    <meta name="description" content={`Cheap ${name} to buy in Ghana. Explore affordable ${name} in Ghana on Qloza.com`} />
                    <meta property="og:image" content={`/images/categories/${image}`} />
                    <meta name="keywords" content={extra} />



                </Helmet>



                <div className="fixed-header" style={{
                    marginTop: '20px',
                    marginBottom: '20px',
                    color: COLOR_LIGHTER,
                    position: 'fixed',
                    top: -20,
                    width: '100%',
                    backgroundColor: COLOR_PRIMARY,
                    zIndex: '1000',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px'
                }}>

                    <div style={{ textAlign: 'right' }}>
                        <MdMenu size={30} style={{ cursor: 'pointer' }} onClick={toggleMenu} />
                    </div>


                    {showMenu && (
                        <div style={{
                            backgroundColor: COLOR_LIGHTER, padding: '10px', border: '1px solid #ccc',
                            position: 'fixed', zIndex: '1', marginTop: '40%', marginLeft: '2%'
                        }}>
                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                                {CATEGORIES.map((category) => (
                                    <Link
                                        to={`/category/${category.name}`}
                                        state={{ category: category.name }}
                                        style={{ textDecoration: 'none', color: 'inherit' }}
                                    >
                                        <li key={category.id} style={{
                                            marginBottom: '5px', borderBottomColor: '#f00',
                                            borderBottomWidth: '1px'
                                        }} onClick={toggleMenu} >
                                            <p style={{ color: COLOR_PRIMARY }}>{category.name}</p>
                                        </li>
                                    </Link>
                                ))}
                            </ul>
                        </div>
                    )}
                    <Link to="/" style={{ textDecoration: 'none', color: COLOR_PRIMARY }}>
                        <img
                            src={`/images/qloza_.png`}
                            className="card-img-top"
                            loading='lazy'
                            alt={"Home"}
                            style={{
                                objectFit: 'cover',
                                width: '150px',
                                height: '110px',
                                marginTop: -20,
                                marginRight: '10px'
                            }}
                        />
                    </Link>
                    <div style={{ flex: 1 }}>
                        <input
                            type="text"
                            placeholder="Search..."
                            className="form-control mb-3"
                            style={{ width: '93%' }}
                            value={searchTerm}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyPress}
                        />
                        {showSuggestions && (
                            <ul className="list-group" style={{
                                zIndex: 12, position: 'absolute',
                                width: '40%', display: searchTerm ? 'flex' : 'none'
                            }}>
                                <Link
                                    to={`/search/item/`}
                                    state={{ param: searchTerm }}
                                    style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <li

                                        className="list-group-item"
                                        onClick={() => handleSuggestionClick(searchTerm)}
                                        style={{ cursor: 'pointer', display: searchTerm ? 'flex' : 'none' }}>
                                        {searchTerm}
                                    </li>
                                </Link>
                                {suggestions.map((suggestion, index) => (
                                    <Link
                                        to={`/search/item/`}
                                        state={{ param: suggestion.name }}
                                        style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <li
                                            key={index}
                                            className="list-group-item"
                                            onClick={() => handleSuggestionClick(suggestion.name)}
                                            style={{ cursor: 'pointer' }}>
                                            {suggestion.name}
                                        </li>
                                    </Link>
                                ))}
                            </ul>
                        )}
                    </div>
                    {/* <div className="col-md-6">
                    <p style={{ padding: 10 }}>{name}</p>
                </div> */}
                </div>


                <div className="row" style={{ padding: '10px', marginTop: '100px', display: 'flex', flexWrap: 'wrap' }}>
                    <p style={{ padding: '10px', textTransform: 'uppercase', color: '#000' }}>
                        {name}
                        <span style={{ textTransform: 'none', color: '#222', fontSize: '15px' }}> on Qloza.com</span>
                    </p>

                    {products.map((item) => (
                        <div className="col-lg-3 col-md-4 col-sm-6 col-6" key={item.id}

                            style={{ marginBottom: '20px', padding: '5px' }}>
                            <Link to={`/product/${item._id}`} state={{ product: item }} style={{ textDecoration: 'none', color: '#000' }}>
                                <div className="card" style={{ backgroundColor: '#fcfcfc', borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
                                    <img
                                        src={`${FILE_URL}/${item.images[0]}`}
                                        className="card-img-top"
                                        loading='lazy'
                                        alt={item.name}
                                        style={{ objectFit: 'cover', width: '100%', height: '200px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}
                                    />
                                    <div className="card-body">
                                        <h5 className="card-text" style={{ color: COLOR_PRIMARY, fontWeight: 'bold', fontSize: '18px' }}>
                                            GH₵ {parseFloat(item.price).toLocaleString()}
                                        </h5>
                                        <h5 className="card-title" style={{ textTransform: 'capitalize', color: '#000', fontSize: '16px' }}>
                                            {item.name}
                                        </h5>
                                        <p className="card-text" style={{
                                            color: COLOR_LIGHT, textTransform: 'capitalize',
                                            marginBottom: '5px', fontSize: 14
                                        }}>
                                            <MdLocationOn size={20} color={COLOR_LIGHTER} />
                                            {item.location}
                                        </p>
                                    </div>
                                </div>
                            </Link>

                        </div>
                    ))}
                </div>





            </div>
            <footer className="row mx-auto py-5" style={{ backgroundColor: COLOR_PRIMARY, color: '#fff', paddingLeft: '10%' }}>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                    &copy; {new Date().getFullYear()} Qloza E-commerce. All Rights Reserved.
                    <div style={{ marginTop: '10px' }}>
                        <span style={{ color: COLOR_LIGHTER }}>Download Qloza to your phone below</span>
                        <div className="d-flex align-items-center mt-2">
                            <a href={playStoreURL} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>
                                <img
                                    src={`/images/playstore.png`}
                                    style={{ width: '140px', height: '50px' }}
                                    alt={"Download on Play Store"}
                                />
                            </a>
                            <a href={appStoreURL} target="_blank" rel="noopener noreferrer">
                                <img
                                    src={`/images/appstore.png`}
                                    style={{ width: '120px', height: '40px' }}
                                    alt={"Download on App Store"}
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                    <a href="https://www.instagram.com/qlozagh/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: COLOR_LIGHTER, display: 'flex', alignItems: 'center' }}>
                        <FaInstagram size={24} style={{ color: '#C13584', marginRight: '5px' }} /> Qloza Ghana
                    </a>
                </div>
            </footer>

        </body>

    );
};

export default Category;
